<template>
  <div v-if="show" class="modal-detail">
    <vxe-modal v-model="show" :title="name" width="620" min-width="620" showFooter esc-closable mask-closable resize>
      <template v-slot>
        <div>
          <el-transfer v-model="dataValues" :data="dataList" filterable :props="{ key: 'id', label: 'name' }"
            :titles="['未分配的角色', '已分配的角色']"></el-transfer>
        </div>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <el-button size="small" @click="cancel" width="300">取 消</el-button>
          <el-button size="small" type="primary" @click="save" width="300">确 定</el-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: '',
      dataList: [],
      dataValues: [],
      row: {},
      loading: false
    }
  },
  methods: {
    display(data) {
      this.show = true
      this.dataList = data.dataList
      this.dataValues = data.dataValues
      this.row = data.row
      this.name = data.name
    },
    cancel() {
      this.show = false
    },
    save() {
      this.loading = true
      const params = {
        userId: this.row.userId,
        roleIds: this.dataValues
      }
      this.$axios
        .post('/auth/user/updateUserRole', params)
        .then(res => {
          if (res) {
            this.$message.success('分配成功')
            this.show = false
            this.$emit('init')
          }
          this.loading = false
        })
        .catch(err => {
          console.log('分配失败', err)
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-detail {
  ::v-deep .vxe-modal--content {
    white-space: normal !important;
  }
}
</style>