<template>
  <div v-if="show" class="drawer">
    <el-drawer destroy-on-close :visible.sync="show" size="800px" :show-close="false">
      <div class="drawer-show">
        <div class="drawer-show-header">
          <div class="drawer-show-header-body">
            <h2 class="fl drawer-show-add-name">
              <a href="javascript:void(0);" @click="copyName" class="copy-a" :data-clipboard-text="name">{{ name }}</a>
            </h2>
            <div class="fr">
              <el-button class="drawer-show-add-close" type="text" icon="el-icon-close" @click="show = false" />
            </div>
          </div>
        </div>
        <div class="drawer-show-body">
          <div style="margin-top: 20px">
            <el-tabs v-model="activeName" type="border-card">
              <el-tab-pane label="基本信息" name="基本信息">
                <div class="drawer-item">
                  <p class="drawer-item-name">用户名称</p>
                  {{ userData.userName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">手机号</p>
                  {{ userData.phone }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">已分配的角色</p>
                  <el-tag v-for="(item, index) in userData.list" :key="index" type="success" disable-transitions
                    size="small" style="margin:5px">{{item.name}}
                  </el-tag>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      userData: {},
      activeName: '基本信息',
      name: ''
    }
  },
  methods: {
    display(data) {
      this.show = true
      this.userData = data.userData
      this.name = data.userData.userName
    },
    copyName() {
      let clipboard = new this.Clipboard('.copy-a')
      clipboard.on('success', () => {
        this.$message.success('复制成功')
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$message.error('该浏览器不支持自动复制')
        clipboard.destroy()
      })
    }
  }
}
</script>

