<template>
  <div v-if="show" class="modal-detail">
    <vxe-modal v-model="show" :title="name" width="400" min-width="400" height="210" min-height="210" showFooter
      esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="userData" label-width="120px" label-position="top" :rules="rules">
          <el-form-item prop="phone" label="手机号">
            <el-input v-model="userData.phone" placeholder="请输入手机号" type="text"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">邀请</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value) {
        if (this.$validate.validateMobile(value)) {
          callback()
          this.isCode = true
        } else {
          callback(new Error('请输入正确的11位手机号'))
          this.isCode = false
        }
      } else {
        callback(new Error('请输入手机号'))
        this.isCode = false
      }
    }
    return {
      show: false,
      name: '',
      userData: {},
      rules: {
        phone: [{ required: true, trigger: 'blur', validator: validatePhone }]
      },
      loading: false
    }
  },
  methods: {
    display(data) {
      this.show = true
      this.userData = data.userData
      this.name = data.name
    },
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = { phone: this.userData.phone }
          this.$axios
            .post('/auth/user/addMember', params)
            .then(res => {
              if (res) {
                this.$message.success('邀请成功')
                this.show = false
                this.$emit('init')
              }
              this.loading = false
            })
            .catch(err => {
              console.log('邀请用户失败', err)
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-detail {
  ::v-deep .vxe-modal--content {
    white-space: normal !important;
  }
}
</style>