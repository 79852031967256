<template>
  <div class="base-container">
    <div class="cf" style="margin-bottom: 10px">
      <div class="fl">
        <el-button type="primary" @click="getAdd()" size="mini" class="el-icon-s-custom"
          v-if="!['', null, undefined].includes(tenantName)">
          邀请用户
        </el-button>
        <el-button @click="exportDataEvent()" size="mini">导出</el-button>
        <el-button @click="printDataEvent()" size="mini">打印</el-button>
      </div>
      <div class="fr">
        <el-button @click="init()" size="mini">刷新</el-button>
      </div>
    </div>
    <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
      highlight-hover-row auto-resize size="small" ref="diverSign" resizable row-id="id" v-loading="loading"
      element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow show-overflow>
      <template #empty>
        <el-empty></el-empty>
      </template>
      <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
      <vxe-table-column field="headImg" width="80" title="用户头像">
        <template v-slot="{ row }">
          <!-- <el-avatar v-if="row.headImg && !['1', '2', '3', '4', '5', '6', '7', '8'].includes(row.headImg)"
            :src="row.headImg" class="avatar" shape="square" style="width: 38px; height: 38px; margin-top: 1px">
          </el-avatar> -->
          <el-avatar class="avatar" shape="square" style="width: 38px; height: 38px; margin-top: 1px">
            {{row.userName.slice(-2)}}</el-avatar>
        </template>
      </vxe-table-column>
      <vxe-table-column field="userName" width="100" title="用户名称"></vxe-table-column>
      <vxe-table-column field="phone" width="150" title="手机号"></vxe-table-column>
      <vxe-table-column field="roleList" min-width="200" title="已分配的角色">
        <template v-slot="{ row }">
          <el-tag v-for="(item, index) in row.list" :key="index" type="success" disable-transitions size="small"
            style="margin:5px">{{item.name}}
          </el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column fixed="right" align="center" width="160" title="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="userRole(scope.row)" icon="el-icon-c-scale-to-original">分配角色
          </el-button>
          <el-button type="text" size="mini" icon="el-icon-view" @click="getCheck(scope.row)">查看</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <check ref="check" />
    <add ref="add" @init="init" />
    <user-role ref="userRole" @init="init" />
  </div>
</template>

<script>
import check from './components/check'
import add from './components/add'
import userRole from './components/userRole'

export default {
  name: 'user',
  data() {
    return {
      data: [],
      tableHeight: window.innerHeight - 190 + 'px',
      allAlign: 'center',
      loading: false,
      tenantName: null,
      isAdmin: false,
    }
  },
  created() {
    this.init()
    const userRole = sessionStorage.getItem('userRole')
    const userList = !['', null, undefined].includes(userRole) ? userRole.split(',') : []
    this.isAdmin = userList.includes('admin') ? true : false
  },
  components: { check, add, userRole },
  mounted() {
    //监听元素变化
    window.onresize = () => {
      if (this.$refs.diverSign) {
        this.tableHeight = window.innerHeight - this.$refs.diverSign.$el.offsetTop - 125 + 'px'
      }
    }
    this.tenantName = localStorage.getItem('tenantName')
  },
  methods: {
    init() {
      this.loading = true
      this.$axios
        .get('/auth/user/queryMemberList')
        .then((res) => {
          if (res) this.data = res.data.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    exportDataEvent() {
      this.$refs.diverSign.openExport()
    },
    printDataEvent() {
      this.$refs.diverSign.openPrint()
    },
    getCheck(row) {
      this.$axios.get(`/auth/user/getUserDetail/${row.userId}`).then((res) => {
        if (res) {
          let data = {
            userData: res.data.data,
          }
          this.$refs.check.display(data)
        }
      })
    },
    getAdd() {
      let data = {
        userData: {},
        name: '邀请用户',
      }
      this.$refs.add.display(data)
    },
    userRole(row) {
      const data = { dataList: [], dataValues: [], name: '分配角色', row: row }
      this.$axios.get('/auth/tenant/role/list').then((res) => {
        if (res) {
          data.dataList = res.data.data
          data.dataValues = this.$utils.map(row.list, (item) => item.id)
          this.$refs.userRole.display(data)
        }
      })
    },
  },
}
</script>

<style scoped></style>
